.infinite-scroll-component__outerdiv {
  display: flex;
  flex: 1;
  max-width: 100%;
}
.infinite-scroll-component {
  display: flex;
  flex: 1;
  flex-direction: column;
}

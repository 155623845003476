.float-banner{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background: rgba(255,255,255,.97);
    box-shadow: 0 2px 2px -2px rgb(0 0 0 / 15%);
    transition: top .2s ease-in-out;
    background-color: #ffffff;
    color: #020202;
    padding: 4px 0px 8px 0px;
    height: auto;
    font-family: Inter;
    font-size: 14px;
    text-align: center;
  }
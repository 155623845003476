.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.slick-next {
  right: 25px;
  top: -20px;
}
.slick-prev {
  right: 55px;
  top: -20px;
  left: unset;
}

.slider-container {
  margin-top: 40px;
}
a {
  color: white;
}

/* scrollbar styling based on theme*/

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222222;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

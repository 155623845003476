.testimonial_customer-image {
    width: 92px;
    height: 92px;
    min-height: auto;
    min-width: auto;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.testimonial_client-image-wrapper {
    margin-right: 1rem;
}

.testimonial_client {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.testimonial_client-info {
    font-size: 18px;
}

.testimonial_client {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

.testimonial_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: solid;
    border-width: 1px;
    border-color: #cdcdcf;
    background: rgb(0,0,0,0.65);
}

.testimonial_text-weight-semibold {
    margin-bottom: 0px;
    font-family: JetBrains Mono,Inter;
    font-weight: 400;
    font-size: 1rem;
}

.testimonial_text-size-medium-2 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
}
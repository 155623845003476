.dashboard-video {
  width: "90%";
  vertical-align: middle;
  margin: 0 auto;
  height: 430px !important;
}

@media (max-width: 600px) {
  .dashboard-video {
    height: 160px !important;
  }
}

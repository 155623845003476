@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,600;0,700;0,800;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=JetBrains+Mono:ital,wght@0,600;0,700;0,800;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  :-webkit-scrollbar {
    display: none;
  }
}

video {
  object-fit: cover;
}
